<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.fuwudizhi')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div style="height: 10px;"></div>
        <div>

            <div class="addr_list" v-for="(item,index) in list" :key="index" @click="ischeckedAddr(item)">
                <div style="width: 70%;">
                    <div class="addr_list_username"> {{item.name}}  {{item.mobile}}</div>
                    <div class="addr_list_useraddr">{{ item.address }}</div>
                </div>
                <div style="flex: 1;"></div>
                <template>

                    <van-icon name="edit" size="26" style="margin-right: 4px;" @click.stop="editAddr(item)" />
                <!-- <van-icon name="delete-o" size="26" color="#e82c2f" /> -->
                </template>
            </div>
        </div>
        
        <div style="margin: 16px;" @click="$router.push({path:'/editAddr'})">
            <van-button round block type="info" native-type="submit">{{$t('news.xinzeng')}}</van-button>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs,
            list:[],
            ischecked:this.$route.query.ischecked
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'getUserAddess'
        }).then(res => {
            console.log(res.data)
            this.list = res.data
        })

        this.$store.commit('setAddress_store', null);
    },

    mounted() {
    },
    methods: {
        ischeckedAddr(item){
            if(this.ischecked){
                this.$store.commit('set_order_from', {
                    ...this.$store.getters.getorder_from_store,
                    ...this.info,
                    name:item.name,
                    mobile:item.mobile,
                    address:item.address,
                    address_id:item.id,
                    service_realLat:item.realLat,
                    service_realLng:item.realLng

                 });
                 this.back()
            }
        },
        editAddr(item){
            this.$router.push({path:'/editAddr?id='+item.id})
        },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.addr_list {
    display: flex;
    align-items: center;
    background: #fff;
    margin: 10px 30px;
    padding: 30px;
    border-radius: 20px;
}

.addr_list_username {
    font-size: 28px;
    margin-bottom: 20px;
}

.addr_list_useraddr {
    font-size: 24px;
}
.nHome-detail{
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}

.van-button {
    width: 100%;
    font-size: 28px;
    height: 2.125rem;
    border-radius: 6.1875rem !important;
    background: #f676b0;
    border-color: #f676b0;
}
</style>