<template>
  <div class="home-container">
    <div class="header linear-bg">

      <GmapMap v-if="isMap" style="position: fixed;top: -9999999999999px;left: -999999999px;z-index: -1;" :center="latlng">
      </GmapMap>
      <div style="display: flex;justify-content: center;align-items: center;" @click="toServerAddr()">
        <van-icon name="location-o" />
        <span class="addr">{{ base_addr_info ? base_addr_info.name : '' }}</span>
        <van-icon name="arrow-down" />
      </div>

      <div style="flex: 1;"></div>
      <van-icon name="chat-o" size="24" color="#fff" @click="$router.push({ path: '/Notice' })" />
    </div>

    <div class="mask" v-if="isLoading && video_login == 1 && !token">
      <van-loading type="spinner" size="80px" />
    </div>
    <div class="home-scroll">
      <div class="banner">
        <swiper class="banner_swiper" :options="bannerSwiperOption">
          <swiper-slide v-for="(v, key) in banners" :key="key">
            <van-image class="banner_img" round :src="v.url">
              <template v-slot:loading>
                <van-loading type="circular" />
              </template>
            </van-image>
          </swiper-slide>
        </swiper>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{ $t('index.task') }}</span>
          </div>
          <div @click="gotoMenu('/Game')">
            <span>{{ $t('index.more') }}</span>
            <van-icon name="arrow" color="#303133" />
          </div>
        </div>
        <div class="hot-items-div">
          <van-grid :border=false :column-num="4" :icon-size="20">
            <van-grid-item @click="toLottery(v.key, v.id)" v-for="(v, key) in gameitem" :key="key">
              <van-image class="game_item_img game_img" :src="v.ico">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
              <span>{{ v.name }}</span>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="hot-game">
        <div class="hot-title-div">
          <div>
            <span>{{ $t('index.tianxin') }}</span>
          </div>
          <div @click="gotoMenu('/allList')">
            <span>{{ $t('index.more') }}</span>
            <van-icon name="arrow" color="#303133" />
          </div>
        </div>
        <div class="hot-items-div" v-if="movielist_2.length > 0">
          <van-grid :border=false :column-num="3" :icon-size="20">
            <van-grid-item @click="toPlayVideo(v.id)" v-for="(v, key) in movielist_2.slice(0, 3)" :key="key">
              <van-image class="game_item_img" :src="v.img_url">
                <template v-slot:loading>
                  <van-loading type="circular" />
                </template>
              </van-image>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
      <div class="home_tabs">
        <div class="home_tabs_item" :class="{ 'home_tabs_active': home_tabs_index == item.id }"
          v-for="(item, index) in home_tabs" :key="index" @click="tabs_click(item.id)">{{ item.name }}</div>
      </div>

      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
        <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false" :loading-text="$t('refresh.loading')"
          :finished-text="$t('video.no_more')" @load="onLoad">
          <div class="movie_list_n" v-if="movielist_1.length > 0">
            <div class="movie-list-n-item" v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <div style="position: relative;">
                <img class="movie-list-n-item-img" :src="v.img_url" alt="">
                <div class="movie-list-n-item-addr">{{ $t('news.juli') }} {{ v.distance }}</div>
              </div>
              <div class="movie-list-n-item-bottom">
                <div class="movie-n-time-div">
                  <div class="movie-list-addr">
                    <!-- <van-icon name="location-o" /> -->
                    {{ v.xuanfei_name }}
                  </div>
                  <div class="movie-list-ico" @click.stop="likeTap(v,key)">
                    <van-icon name="like" color="#f676b0" v-if="v.is_love" />
                    <van-icon name="like-o" color="#f676b0" v-else />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </van-list>

      </van-pull-refresh>
    </div>
    <van-popup v-model="pop_content" round closeable close-icon="close"
      style="width:70%;height: 50%;font-size: 24px;color: #000;">
      <div class="pop_content-content" v-html="pop_content_txt"></div>


    </van-popup>


    <img class="luckDraw" v-if="isluckdraw == '1'" @click="toLuckDraw" src="@/assets/luckdraw.png" alt="">
    <img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
export default {
  data() {
    return {
      notice: this.$t("index.loading"),
      banners: [{}],
      basicData: [],
      gameitem: [{}, {}, {}, {}],
      movielist_0: [{}, {}, {}, {}],
      movielist_1: [],
      loading: false,
      finished: false,
      isLoading: true,
      movielistSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup: 1,
      },
      bannerSwiperOption: {
        speed: 800,
        autoplay: true,
      },
      video_login: this.$store.getters.getBaseInfo.video_login || 1,
      token: localStorage.getItem('token'),
      isluckdraw: null,
      pop_content_txt: '',
      pop_content: false,
      kefu: '',
      iskefu: 0,

      coordinate: {
        client: {},
        elePosition: {}
      },
      page: 1,

      home_tabs: [
        {
          name: this.$t('news.daodian'),
          id: 1
        },
        {
          name: this.$t('news.daojia'),
          id: 2
        },
        {
          name: this.$t('news.nvyou'),
          id: 3
        },
        {
          name: this.$t('news.lvyou'),
          id: 4
        }
      ],
      home_tabs_index: 1,
      movielist_2: [],
      base_addr_info: this.$store.getters.getbase_addr_store || '',
      latlng: {

        lat: 0,
        lng: 0
      },
      isMap:true

    };
  },
  computed: {
    google: gmapApi, // 获取官方的OBject 使用官方API的时候可以用
  },
  methods: {
    likeTap(item,key){
      
      if(!localStorage.getItem('token')){
        return this.$router.push({path:'/Login'})
      }
      // this.movielist_1[key]['is_love'] = !this.movielist_1[key]['is_love']
      this.$http({
        method: 'get',
        url: 'doLove',
        data: { xf_id: item.id }
      }).then(res => {
        if(res.code == 200){
          this.$set(this.movielist_1[key],'is_love',!this.movielist_1[key]['is_love'])
        }
        this.$toast(res.msg)
      });
    },
    toServerAddr(){
      if (!localStorage.getItem('token')) {
        this.$router.push({ path: '/Login' })
      } else {
        this.$router.push({ path: '/server_addr' })
      }
    },

    geolocate: function () {
      let that = this
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position && position.coords && position.coords.latitude) {
            // alert("获取地理位置："+position.coords.latitude+","+position.coords.longitude)
            if(!this.$store.getters.getbase_addr_store){
              
              localStorage.setItem('lat', position.coords.latitude)
              localStorage.setItem('lng', position.coords.longitude)
            }
            setTimeout(() => {
              that.reverseGeocode({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              })
            }, 300)
            that.latlng = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            }
          }
        }, (error) => {  // html5 默认调用的谷歌的接口，会有安全限制
          switch (error.code) {
            case error.PERMISSION_DENIED: // 许可拒绝,用户选了不允许
              // alert("您拒绝对获取地理位置的请求");
              // alert(error.message);
              break;
            case error.POSITION_UNAVAILABLE: // 连不上GPS卫星，或者网络断了
              // alert("位置信息是不可用的");
              // alert(error.message);
              break;
            case error.TIMEOUT:  // /超时了
              // alert("请求您的地理位置超时");
              // alert(error.message);
              break;
            case error.UNKNOWN_ERROR:
              // alert("未知错误");
              // alert(error.message);
              break;
          }
        });
      }
    },
    reverseGeocode(location) {

      let that = this
      // 实例化Geocoder服务用于解析地址
      var geocoder = new that.google.maps.Geocoder();
      // 地理反解析
      geocoder.geocode({ location: new that.google.maps.LatLng(location.lat, location.lng) }, function geoResults(results, status) {
        if (status === 'OK') {
          if (!that.$store.getters.getbase_addr_store) {
            that.base_addr_info = {
              name: results[0].formatted_address,
              realLat: location.lat,
              realLng: location.lng
            }
            that.$store.commit('setbase_addr_store', {
              name: results[0].formatted_address,
              realLat: location.lat,
              realLng: location.lng
            });
            that.isMap = false;
          }
        }
      });
    },
    getxuanfeilist() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { id: this.$route.query.id }
      }).then(res => {
        this.datalist = res.data;
      });
    },
    toKefu() {

      window.location.href = this.kefu
    },
    tabs_click(id) {
      this.home_tabs_index = id

      this.movielist_1 = [];
      this.getNhomeList()
    },
    touchStartHandle(refName, e) {
      let element = e.targetTouches[0]
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY
      }
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop
    },
    drag(refName, e) {
      let element = e.targetTouches[0]
      // 根据初始client位置计算移动距离（元素移动位置+光标移动后的位置-光标点击时的初始位置）
      let x = this.coordinate.elePosition.left + (element.clientX - this.coordinate.client.x)
      let y = this.coordinate.elePosition.top + (element.clientY - this.coordinate.client.y)
      // 限制可移动距离，不超出可视区域
      x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
      // 50是头部的高度
      y = y <= 50 ? 50 : x >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
      // 移动当前元素
      this.$refs[refName].style.left = x + 'px'
      this.$refs[refName].style.top = y + 'px'
    },
    toLuckDraw() {
      this.$router.push({ path: '/luckDraw' })
    },
    gotoMenu(router) {
      this.$router.replace(router)
    },
    toLottery(key, id) {
      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/Lottery?key=' + key + "&id=" + id })
        }
      }

    },
    // toPlayVideo(id) {

    //   if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
    //     this.$router.push({path:'/Login'})
    //   } else {
    //     if (id) {
    //      this.$router.push({path:'/PlayVideo?id='+id})
    //     }
    //   }
    // },

    toPlayVideo(id) {

      if (!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1) {
        this.$router.push({ path: '/Login' })
      } else {
        if (id) {
          this.$router.push({ path: '/technicianDetail?id=' + id })
        }
      }
    },
    // onRefresh() {
    //   setTimeout(() => {
    //     this.getBasicConfig();
    //     this.isLoading = false;
    //     this.$toast(this.$t('reservation.refresh'));
    //   }, 500);
    // },

    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig() {
      this.$http({
        method: 'get',
        url: 'sys_config'
      }).then(res => {
        this.basicData = res.data;
        this.getNotice(this.basicData);//获取公告
        this.getBanner(this.basicData);//获取banner
        this.getGameItem();//获取首页游戏列表
        this.getMovieList_0(this.basicData);//获取首页视频0
        // this.getMovieList_1(this.basicData);//获取首页视频1

        this.$nextTick(() => {
          this.lazyLoadImg()
        })
      })

    },
    getNotice(data) {
      this.notice = data.notice || '';
    },
    getGameItem() {
      this.$http({
        method: 'get',
        url: 'lottery_hot'
      }).then(res => {
        this.gameitem = res.data
      })
    },
    getMovieList_0(data) {
      this.movielist_0 = data.movielist_0
    },
    getMovieList_1(data) {
      this.movielist_1 = data.movielist_1
    },
    getBanner(data) {
      this.banners = data.banners;
    },
    query(selector) {
      return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
        this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    },

    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'xuanfeilist',
        data: { page: this.page, type: this.home_tabs_index },
      }).then(res => {
        this.loading = false;
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });

    this.$nextTick(() => {

      this.geolocate()
    })
  },
  created() {
    this.$http({
      method: 'get',
      url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if (!localStorage.getItem('token') && res.data.video_login == 1) {
          return this.$router.push({ path: '/Login' })
        }
        this.getNhomeList()
        this.isLoading = false
        this.isluckdraw = res.data.isluckdraw
        this.pop_content_txt = res.data.pop_content
        this.kefu = res.data.kefu
        this.iskefu = res.data.iskefu
        if (res.data.ispop == 1) {
          this.pop_content = true
        }
        this.$store.commit('setBaseInfoValue', res.data);
        this.getBasicConfig();
      }
    })

    this.$http({
      method: 'get',
      url: 'xuanfeilist',
    }).then(res => {
      if (this.page == 1) {
        this.movielist_2 = res.data;
      }
    })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
  height: 200px;
  display: flex;
  align-items: center;

  background: linear-gradient(180deg, #F676B0 1%, #f5f5f5 119%) !important;
}

.home-container {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f5f5f5;
}

.linear-gradient {
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
  min-width: 40px;
}

.notice-swipe {
  width: calc(100% - 50px);
  height: 85px;
  font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
  transform: scale(2.5);
}

.banner {
  width: 100%;
  padding: 0 30px;
  // margin-top: -23%;
}

.banner_swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 620px;
    height: 240px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    background-color: #ffffff;
    background-position: center;
    background-size: cover;
    color: #ffffff;
  }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.hot-game {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.hot-title-div {
  width: calc(100% - 50px);
  margin: 0 auto;
  // height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hot-title-div>div:first-child {
  width: 430px;
}

.hot-title-div div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 20px;
  color: #979799;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 10px;
  font-weight: 700;
  color: #303133;
}

.hot-title-div>div:first-child span {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #303133;
}

.hot-title-div>div:nth-child(2) span {
  font-size: 25px;
  color: #303133;
}

.hot-title-div>div:first-child span:before {
  content: "";
  display: block;
  width: 5px;
  height: 30px;
  background-color: #F676B0;
  border-radius: 1px;
  margin-right: 5px;
}

.hot-game .hot-items-div {
  margin-top: -3px;
}

.hot-game .hot-items-div span {
  margin-top: 10px;
  font-size: 24px;
  color: #303133;
}

.hot-recommend {
  width: 100%;
  flex: 1;
  background-color: #f2f2f5;
}

.movie_swiper {
  .swiper-slide {
    width: 80%;
    height: 330px;
  }
}

.movie_list_0 {
  width: calc(100% - 50px);
  margin: 0 auto;
}

.movie_cover {
  border-radius: 10px;
  width: 550px;
  height: 330px
}

.movie_list_0 .movie-list-item-bottom {
  position: relative;
  width: 550px;
  bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
  font-size: 28px;
  color: #fff;
}

.movie_list_0 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 80px;
}

.movie_list_0 span {
  font-size: 30px;
}

.movie_list_1 {
  display: flex;
  width: calc(100% - 50px);
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.home-scroll {
  padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
  margin-bottom: 10px;
  width: 335px;
  height: 290px;
}

.movie_list_1 .movie-list-item-bottom {
  position: relative;
  width: 335px;
  bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
  margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
  background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}

.movie_list_1 .movie-time-div {
  color: #fff;
  border-radius: 0 0 10px 10px;
  height: 35px;
}

.movie_list_1 .movie_swiper .hot {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
  padding-right: 110px;
  font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 350px;
  padding-left: 10px;
  font-size: 25px;
}

.hot-recommend-more {
  width: 100%;
  padding-bottom: 20px;
  text-align: center;
  color: #303133;
  font-size: 30px;
}

.hot-items-div {
  padding: 12px 0;
}

.hot-items-div .game_item_img {
  width: 220px;
  height: 220px;
  margin-bottom: 16px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
  border-radius: 20px;
}

.game_img {

  width: 100px !important;
  height: 100px !important;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.luckDraw {
  position: fixed;
  bottom: 160px;
  right: 40px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  z-index: 999;
}

.kefu {
  position: fixed;
  bottom: 300px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
  // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
  font-size: 50px;
}

.pop_content-content {
  font-size: 30px;
  color: #1c1e23;
  padding: 30px;
  margin-top: 40px;
}





.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.bd-info .info-model .model-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  background-color: #8f65fe;
  border-radius: 0.5rem;
  margin: 0.625rem;
  padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
  position: relative;
  width: 7.5rem;
  overflow: hidden;
  border-radius: 10px;
  margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
  width: 7.5rem;
  height: 11.25rem;
  border: 0;
  vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
  margin-bottom: 0.625rem;
  margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
  font-size: .75rem;
  color: #fff;
  display: inline-block;
  margin-right: 0.3125rem;
  margin-bottom: 0.3125rem;
  background-color: #ea92cf;
  padding: 0.1875rem 0.3125rem;
  border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
  margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
  // height: 2.625rem;
  line-height: 1.6;
  overflow: hidden;
  margin-bottom: 0.75rem;

  display: -webkit-box;

  overflow: hidden;

  white-space: normal !important;

  text-overflow: ellipsis;

  word-wrap: break-word;

  -webkit-line-clamp: 3;

  -webkit-box-orient: vertical
}

.bd-info .info-model .model-item .item-info .call .van-button {
  width: 100%;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
  color: #ddd2fb;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
  display: inline-block;
  color: #fff;
  font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
  color: #ddd2fb;
  font-size: .875rem;
}

.van-button--info,
.van-button--primary {
  border: none !important;
  background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
  background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
  border-radius: 5px !important;
}

.movie_list_n {
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}

.movie-list-n-item {
  padding: 10px;
  width: calc(50% - 10px);
  // background-color: #fff;
  border: 1px solid #fff;
  color: #000;
  margin: 5px;
  // margin: 15px 15px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
  box-shadow: 0 0 10px 4px rgba(202, 202, 202, 0.6);
  border: none;

}

.movie-list-n-item-top {
  padding-top: 40px;
}

.movie-list-n-item-bottom {
  // color: #fff;
  // position: absolute;
  // bottom: 15px;
  // left: 0;
  // padding-left: 10px;
  width: 100%;

}

.movie-list-n-item-img {
  width: 335px;
  height: 335px;
}

.movie-n-time-div {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movie-list-n-title {
  // color: #f905e5;
  color: white;
  display: -webkit-box;
  -webkit-line-clamp: 2;

  font-size: 28px;
  margin: 12px 0 12px;
  line-height: 1.4;
  height: 80px;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.movie-list-n-img {
  width: 330px;
  height: 330px;
  position: relative;
}

.movie-list-n-lab {
  color: #ffffff;
  background: #7f7f7f;
  border-color: #00b300;
  font-size: 18px;
  padding: 8px;
  margin-right: 4px;
  display: inline-block;
}

.movie-list-ico-loves {
  display: inline-block;
  width: 40px;
}


.movie-list-notice {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.movie-list-ico-notice {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

.movie-list-addr {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
width: 85%;
}
.movie-list-ico{
  padding-right: 10px;
}

.movie-list-n-item-addr {
  position: absolute;
  padding: 8px 20px;
  border-top-left-radius: 16px;
  bottom: 0;
  right: 0;
  font-size: 24px;
  -webkit-transform: scale(.95);
  transform: scale(.95);
  -webkit-transform-origin: right bottom;
  transform-origin: right bottom;
  background: #f676b0 !important;
  color: #fff;
}

.movie-list-n-item-img {
  width: 335px;
  height: 335px;
  display: block;
}

.movie-list-ico-addr {
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}

@keyframes rainbow_animation {

  0%,
  100% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }
}

.movie-list-money {
  background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent !important;
  animation: rainbow_animation 10s ease-in-out infinite;
  background-size: 400% 100%;
  font-weight: bold;
  position: relative;
  margin-right: 10px;
}

.movie-list-ico-money {
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}

.header {
  height: 88px;
  padding: 0 30px;
}

.addr {
  max-width: 300px;
  overflow: hidden;
display: inline-block;
  margin: 10px;
  // width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home_tabs {
  // padding: 0 30px;
  display: flex;
  margin: 20px 20px;
}

.home_tabs_item {
  // flex: 1;
  padding: 10px 20px;
  border-radius: 30px;
  margin: 0 10px;
  background: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  // width: 25%;
}

.home_tabs_active {
  background: #f676b0 !important;
  color: #fff;
}
</style>
