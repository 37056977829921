<template>
    <div class="nHome-detail projectDetail" v-if="info">
      <van-nav-bar :title="info.xuanfei_name" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
      <div style="height: 10px;"></div>
  
          <van-image :src="info.img_url" style="width: 100%; margin: auto;max-height: 300px;" :show-loading="false" fit="contain"
            @click="openImg(index)" />
     
      <div class="projectDetail_top">
        <div class="projectDetail_top_time">{{ info.title }}</div>
        <div class="projectDetail_top_txt">{{ info.content }}</div>
      </div>
      <van-tabs v-model="active">
        <van-tab :title="$t('news.fuwuneirong')">
          <div  style="padding:15px;line-height: 1.5;" v-html="info.service_content"></div>
          
        </van-tab>
        <van-tab :title="$t('news.yuyuexuzhi')">
          <div style="padding: 0 15px;" v-html="serveKnow"></div>
        </van-tab>
      </van-tabs>

      <div class="projectDetail_footer">
        <img class="projectDetail_footer_ico" src="/img/money_icon.png?t=123" alt=""> {{ info.money }}
        <div style="flex: 1;"></div>
        <div class="projectDetail_footer_btn" @click="toappointment()">{{ $t('news.lijiyuyue') }}</div>
      </div>
       
    </div>
  </template>
  
  <script>
  import { ImagePreview } from 'vant';
  
  import dayjs from 'dayjs'
  export default {
    data() {
      return {
        active:0,
        btnLogin: false,
        info: null,
        commentlist: [],
        dayjs,

        "serveDesc": "<p><span style=\"color: rgb(230, 0, 0);\">下單須知：</span></p><p><br></p><p><span style=\"color: rgb(153, 51, 255);\">本平台服务器采用香港时间utc+8</span></p><p><br></p><p>1.甜心到家服務隻提供短期女友体验，對於不正當的行爲和要求，甜心女孩有權拒絶服務。</p><p><br></p><p>2.服務開始過後，因客戶原因提前終止服務的，服務費不予退還。</p><p><br></p><p>3.在平颱規定的服務範圍內如因甜心女孩個人的專業、態度問題導緻客人不滿意而要求退單者，平颱在查驗核實後將會無理由退款。</p><p>退款金額將會在7個工作日內退到客戶的支付賬戶裡。</p><p><br></p><p>4.如客人確認下單後，該客人聯繫確定了服務時間、服務地址，甜心女孩出發了，客人要取消訂單的，該客人所付地接的交通費不與退還。</p><p><br></p><p>5.到達服務地址後，等待時間不得超過20分鐘，過20分鐘後繫統根選下單項目的服務時間自動倒計時。</p><p><br></p><p>6.請務必互相尊重，保持紳士風度！</p>",
        "createTime": "2024-06-25 21:24:27",
        "serveStatus": 0,
        "serveKnow": this.$t("news.yudingxuzhi"),
        "serveIntroduction": this.$t('news.daodian60') ,
        
      };
    },
    created() {
      // this.$http({
      //   method: 'get',
      //   url: 'xuanfeidata',
      //   data: { id: this.$route.query.id }
      // }).then(res => {
      //   console.log(res.data)
      //   this.info = res.data
  
      // })
      console.log(this.$store.getters.getorder_from_store)
      this.$http({
            method: 'get',
            url: 'service',
            
            data:{
                    xf_id:this.$store.getters.getorder_from_store.xf_id
                }
        }).then(res => {
            this.info = res.data[this.$route.query.index]
        })
  
    },
  
    mounted() {
    },
    methods: {
      toappointment(){
        
        console.log(this.$store.getters.getorder_from_store)
        this.$store.commit('set_order_from', {
          ...this.$store.getters.getorder_from_store,
          ...this.info

            });
            console.log(this.$store.getters.getorder_from_store)
        this.$router.push({path:'/appointment'})
      },
      openImg(index) {
        let that = this;
        ImagePreview({
          images: that.info.img_url,
          startPosition: index,
        });
      },
      back() {
        if (this.url) {
  
          this.$router.replace({ path: '/Mine' })
        } else {
          window.history.back();
        }
      },
  
    }
  };
  </script>
  
  <style scoped lang="less">
  .nHome-detail {
    background: #f2f2f5;
    height: 100vh;
    overflow: auto;
    color: #000;
  }
  
  
  .thumb-example {
    height: 480px;
  }
  
  .swiper {}
  
  
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
  .gallery-top {
    height: 300px;
  }
  
  .gallery-thumbs {
    height: 300px;
    box-sizing: border-box;
    padding: 30px 0;
    overflow: hidden;
  }
  
  .gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
  .gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
  
  .information-box {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
      color: #7d879c;
    .title{
      
      font-size: 32px;
    }
  }
  .products-title {
  
    .h4{
      font-size: 40px;
      font-weight: bold;
    }
  }
  .product-description{
    label{
      color: 32px;
      font-weight: bold;
    }
    p{
      color: #7d879c;
      line-height: 1.4;
    }
  }
  .title1{
    margin: 20px 0;
  }
  .nav-thumbs{
    margin-bottom: 30px;
  }
  .detail_content{
    padding: 30px;
  }
  .sdt {
      font-weight: bold;
      color: #ff6f61;
      font-size: 40px;
  }

  .projectDetail_top{
  padding: 30px;
}
.projectDetail_top_time{
  font-size: 28px;
  color: #000;
  margin-bottom: 10px;
}
.projectDetail_top_txt{
color: rgb(137, 137, 137) !important;
font-size: 24px;
}

::v-deep .van-tab {
	font-size: 30px;
	line-height: 100px;
	font-weight: bold;
}
::v-deep .van-tabs{
  margin-bottom: 120px;
}
::v-deep .van-tabs__line {
	background-color: #F676B0;
}
::v-deep .van-tabs--line .van-tabs__wrap {
	height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.projectDetail_footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  // height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background: #fff;
  height: 120px;
  overflow: hidden;
}
.projectDetail_footer_ico{
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.projectDetail_footer_btn{
background: #f676b0!important;
font-size: 28px;
color: #fff;
padding: 20px 30px;
border-radius: 20px;
}

::v-deep .van-tab {
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #F676B0;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}

::v-deep .van-tab--active .van-tab__text {
    color: #F676B0;
}
  </style>
  