<template>
    <div class="nHome-detail" v-if="info">
        <van-nav-bar :title="info.xuanfei_name" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div style="height: 10px;"></div>

        <!-- swiper1 -->
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop" style="position: relative;">
            <swiper-slide class="slide-1" style="text-align: center;" v-for="(item, index) in info.img_url"
                :key="index">
                <van-image :src="item" style="width: 60%; margin: auto;" :show-loading="false" fit="contain"
                    @click="openImg(index)" />
            </swiper-slide>
        </swiper>
        <div class="card-info">
            <div class="card-info-title">
                {{ info.xuanfei_name }}
                <div class="card-info-lab" style="margin-left: 10px;">{{ $t('news.kongjiang') }}</div>
            </div>
            <div class="rate_info">
                <van-rate v-model="rate" :size="25" color="#ffd21e" void-icon="star" void-color="#eee" />
                {{ rate }}
                <div class="rate_info_addr"> <van-icon size="15" color="#f676b0" name="guide-o" /> {{ info.distance }}
                </div>


            </div>
            <!-- <div class="right-float txg-active">{{ $t('news.yuyue') }}/ {{ $t('news.jintian') }} 14:00</div> -->
            <div class="descip-info">
                <span>{{ $t('news.nianling') }}:{{ info.birthday }}</span><span>{{ $t('news.shengao') }}:{{ info.height
                    }}</span><span>{{ $t('news.tizhong') }}：{{ info.weight }}</span><span>{{ $t('news.xiongwei')
                    }}：{{ info.three }}</span>
            </div>
            <!-- <div class="descip-info">
                <span>{{ $t('news.yuzhong') }}：</span>
            </div> -->

            <div class="descip-info">
                <div class="product-description">
                    <div class="title1">{{ $t('xuanfei.summary') }}:</div>
                    <div>{{ info.content }}</div>
                </div>
            </div>

        </div>

        <div class="card-info" style="padding-top: 20px;">
            <div class="card-info-title">
                {{ $t('news.tianxin_img') }}
            </div>

            <div class="card_imgs">
                <van-image class="card_imgs_item" radius="6" v-for="(item, index) in info.img_url" :key="index" :src="item"
                    :show-loading="false" fit="cover" @click="openImg(index)" />
            </div>
        </div>
        <div class="txg-list p-15">
            <div class="txg-list-title f-b-c">
                <div class="title-left">{{ $t('news.fuwuneirong') }}</div>
            </div>
            <div class="txg-list-content m-t-10">
                <div class="txg-horizontal-card">
                    <div>
                        <div class="f-s server-box" v-for="(item, index) in list" :key="index">
                            <div class="f-1 f-c-s server-text-box">
                                <div class="main-txt-subtitle pName-text">{{ item.title }}</div>
                                <div class="description-text">
                                    <div style="display: flex; flex-direction: row;">
                                        <div style="flex: 1 1 0%; display: flex; align-items: center;">
                                            <div class="evalCont"
                                                style="-webkit-line-clamp: 1; color: rgb(137, 137, 137) !important;">
                                                {{ item.content }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="f-s-c" style="width: 100%;">
                                    <img class="money_icon" src="/img/money_icon.png?t=123" alt="">
                                    {{ item.money }}
                                </div>
                            </div>
                            <div class="f-c-c">
                                <div class="txg-tag txg-active book-btn" @click="toprojectDetail(index)">
                                    {{ $t('news.yuyue') }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { ImagePreview } from 'vant';

import dayjs from 'dayjs'
export default {
    components: {
        swiper,
        swiperSlide
    },
    data() {
        return {
            swiperOptionTop: {
                loop: true,
                loopedSlides: 50, // looped slides should be the same
                spaceBetween: 10
            },
            btnLogin: false,
            info: null,
            commentlist: [],
            rate: 4,
            dayjs,
            list: []
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'xuanfeidata',
            data: { id: this.$route.query.id }
        }).then(res => {
            this.info = res.data
            // this.$nextTick(() => {
            //     const swiperTop = this.$refs.swiperTop.swiper
            //     const swiperThumbs = this.$refs.swiperThumbs.swiper
            //     swiperTop.controller.control = swiperThumbs
            //     swiperThumbs.controller.control = swiperTop
            // })
            this.$http({
                method: 'get',
                url: 'service',
                data: {
                    xf_id: res.data.id
                }
            }).then(ret => {
                this.list = ret.data
            })
        })

    },

    mounted() {

        this.$store.commit('set_order_from', null);
    },
    methods: {
        toprojectDetail(index) {

            if (!localStorage.getItem('token')) {
                return this.$router.push({ path: '/Login' })
            }
            this.$store.commit('set_order_from', {
                xuanfei_name: this.info.xuanfei_name,
                xuanfei_img: this.info.img_url[0],
                xf_id: this.$route.query.id,
                service_id: this.list[index].id,
                service_realLat: this.info.realLat,
                service_realLng: this.info.realLng
            });
            this.$router.push({ path: '/projectDetail?index=' + index + '&id=' + this.$route.query.id })

        },
        openImg(index) {
            let that = this;
            ImagePreview({
                images: that.info.img_url,
                startPosition: index,
            });
        },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.nHome-detail {
    background: #f2f2f5;
    height: 100vh;
    overflow: auto;
    color: #000;
}


.thumb-example {
    height: 480px;
}

.swiper {}


.swiper-slide {
    background-size: cover;
    background-position: center;
}

.gallery-top {
    height: 650px;
}

.gallery-thumbs {
    height: 300px;
    box-sizing: border-box;
    padding: 30px 0;
    overflow: hidden;
}

.gallery-thumbs .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
}

.gallery-thumbs .swiper-slide-active {
    opacity: 1;
}

.information-box {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px 0;
    justify-content: center;
    align-items: center;
    line-height: 1.4;
    color: #7d879c;

    .title {

        font-size: 32px;
    }
}

.products-title {

    .h4 {
        font-size: 40px;
        font-weight: bold;
    }
}

.product-description {
    label {
        color: 32px;
        font-weight: bold;
    }

    p {
        color: #7d879c;
        line-height: 1.4;
    }
}

.title1 {
    margin: 0px 0 6px;
}

.nav-thumbs {
    margin-bottom: 30px;
}

.detail_content {
    padding: 30px;
}

.sdt {
    font-weight: bold;
    color: #ff6f61;
    font-size: 40px;
}

.right-float {
    position: absolute;
    padding: 10px 20px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 0;
    top: 0;
    right: 0;
    font-size: 24px;
    background: #f676b0 !important;
    color: #fff;
}

.card-info {
    padding: 30px;
    position: relative;
    background: #fff;
    margin-bottom: 30px;
    padding-top: 60px;
}

.card-info-title {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0;
    color: #3d3d3d;
    margin-bottom: 20px;
    line-height: 1.5;
}

.card-info-lab {
    box-sizing: border-box;
    align-items: center;
    border-radius: 8px;
    display: inline-block;
    line-height: 1;
    color: #fff;
    background: linear-gradient(251deg, #ff8400 10%, #ffc15f 83%);
    padding: 6px 20px !important;
}

.f-s-c {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.f-c-s {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.f-s {
    display: flex;
    justify-content: flex-start;
}

.f-c-c {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rate_info {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.rate_info_addr {
    font-size: 20px;
    margin-left: 40px;
    display: flex;
    justify-items: center;
    align-items: center;
}

.descip-info {
    font-size: 24px;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-weight: 700;
    color: #898989;
    display: flex;
    margin: 20px 0;
}

.descip-info span {
    // margin-right: 20px;
    display: block;
    width: 50%;
    margin-bottom: 20px;
}

.txg-list-content {
    display: block;
    width: auto;
    overflow: auto;
}

.txg-list {
    background: #fff;
}

.txg-info {
    position: relative;
    background-color: #fff;
    min-height: 220px;
    border-radius: 0;
    display: flex;
}

.p-15 {
    padding: 30px;
}

.server-box {
    justify-content: space-between;
    width: 100%;
    border-radius: 16px;
    padding: 22px;
    position: relative;
    margin-bottom: 28px;
    z-index: 0;
    border-radius: 8px;
    border: 1px solid hsla(0, 0%, 53.7%, .23921568627450981);
}

.server-text-box {
    padding: 0 30px 0 10px;
    justify-content: space-around;
    flex: 1;
}

.pName-text {
    font-family: FZLTZHK;
    font-weight: 400;
    font-size: 34px;
    color: #000;
    margin-bottom: 20px;
}

.evalCont {
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
}

.description-text {
    font-family: FZLTZHK;
    font-weight: 400;
    font-size: 32px !important;
}

.f-1 {
    flex: 1;
}

.price-text {
    height: 44px;
    width: 44px;
    font-family: FZLTZHK;
    font-weight: 400;
    font-size: 40px;
    color: #000;
}

.book-btn {
    width: 180px;
    height: 74px;
    background: #e67dae;
    border-radius: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    color: #fff;
}

// .server-box:before {
//     content: "";
//     position: absolute;
//     width: 200%;
//     height: 200%;
//     top: -50%;
//     left: -50%;
//     border-radius: 8px;
//     border: 1px solid hsla(0, 0%, 53.7%, .23921568627450981);
//     -webkit-transform: scale(.5);
//     transform: scale(.5);
//     z-index: -1;
// }
.txg-list-title {
    margin-bottom: 20px;
}

.money_icon {
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.card_imgs {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
}

.card_imgs_item {
    width: 150px;
    height: 160px;
    margin: 10px 10px;
    border-radius: 10px;
}
</style>