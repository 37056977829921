<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.myorder')" class="nav-bar">
        </van-nav-bar>
        <div>
            <van-tabs v-model="active" @change="getList" >
                <van-tab :title="item.name" v-for="(item, index) in order_tab" :key="index">

                    <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
                        :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
                        <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false"  :loading-text="$t('refresh.loading')"
                            :finished-text="$t('video.no_more')" @load="onLoad">
                            <div class="movie-list-n-item" v-for="(v,key) in movielist_1" :key="key">

                                <div class="order-top">
                                    <img class="rder-top_img" v-if="v.info" :src="v.info.img_url" alt="">
                                    <div class="rder-top_content">
                                        <div class="rder-top_title" v-if="v.info">{{v.info.xuanfei_name}}</div>
                                        <div class="rder-top_num">{{ $t('news.dingdanghao') }}: <span>12456</span> </div>
                                        <div class="rder-top_time" v-if="v.service_time > 0">{{ $t('news.yuyueshijian') }}:{{ dayjs(v.service_time*1000).format("YYYY-MM-DD HH:mm:ss") }}</div>
                                        <div class="rder-top_money"><span style="color: #228ef3;margin-right: 4px;">{{server_columns_d[v.service_type]}}</span>
                                            
                                            </div>
                                            <div class="rder-top_money">
                                                {{$t('withdraw.money')}}: <span style="color: #ff8400;">{{v.money}}</span>
                                            </div>
                                            
                                    </div>
                                    <div class="rder-top_status">
                                       {{ order_tab_d[v.status] }}
                                    </div>
                                </div>
                                <!-- <div class="order-footer"></div> -->

                            </div>
                        </van-list>

                    </van-pull-refresh>
                </van-tab>

            </van-tabs>

        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
export default {
    data() {
        return {
            dayjs,
            
            loading: false,
            finished: false,
            isLoading: true,
            movielist_1:[],
            page:1,
            active: 0,
            order_tab_d:{
                0:this.$t('news.all'),
                1:this.$t('news.daijiedai'),
                2:this.$t('news.jinxingzhong'),
            },
            
            server_columns_d:{
                0:this.$t('news.daojiafuwu'),
                1:this.$t('news.daodianfuwu')
            },
            order_tab: [
                {
                    id: '',
                    name:this.$t('news.all')
                },
                {
                    id: 0,
                    name: this.$t('news.daijiedai')
                },
                {
                    id: 1,
                    name: this.$t('news.jinxingzhong')
                },
                // {
                //     id: 0,
                //     name: this.$t('news.daipingjia')
                // },
                {
                    id: 2,
                    name: this.$t('news.yiwancheng')
                },
                // {
                //     id: 0,
                //     name:this.$t('news.shouhou')
                // },
            ]
        };
    },
    created() {
        this.getList()
    },

    mounted() {
    },
    methods: {
        getList(){
            this.page = 1
            this.getNhomeList();
            this.isLoading = false;
        },
    onRefresh() {
      setTimeout(() => {
        this.page = 1
        this.getNhomeList();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    getNhomeList() {
      this.$http({
        method: 'get',
        url: 'orderlist',
        data: { page: this.page,status:this.order_tab[this.active].id },
      }).then(res => {
        this.loading = false;
        console.log(res.data,888)
        if (this.page == 1) {
          this.movielist_1 = res.data;
        } else {

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if (res.data.length == 0) {
          this.finished = true;
        } else {
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    },
    onLoad() {
      this.loading = true;
      this.getNhomeList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.nHome-detail {
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}

::v-deep .van-tab {
    font-size: 30px;
    line-height: 100px;
    font-weight: bold;
}

::v-deep .van-tabs__line {
    background-color: #F676B0;
}

::v-deep .van-tabs--line .van-tabs__wrap {
    height: 100px;
}

::v-deep .van-tabs__wrap--scrollable .van-tab {
    padding: 0 23px;
}

::v-deep .van-tab--active .van-tab__text {
    color: #F676B0;
}

.order-top{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.rder-top_img{
    width: 80px;
    height: 80px;
    border-radius: 20px;
    margin-right: 10px;
}
.rder-top_content{
    width: 60%;
    overflow: hidden;
}
.rder-top_title{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px;
}
.rder-top_num{
    font-size: 24px;
    color: #898989;
    margin-bottom: 10px;
    span{
        color: #ff8400;
    }
}
.rder-top_time{
    color: #898989;
    font-size: 24px;
    margin-bottom: 10px;
}
.rder-top_money{
    margin-bottom: 10px;
    
    font-size: 24px;
    color: #898989;
}
.rder-top_status{
    font-size: 24px;
    color: #e82b2f;
}
.movie-list-n-item{
    background: #fff;
    margin: 30px;
    border-radius: 20px;
    padding: 20px 30px;
}
</style>