<template>
    <div class="home-container">
        <!-- <van-dropdown-menu>
            <van-dropdown-item v-model="value1" :options="option1" :title="$t('news.juli')" />
            <van-dropdown-item v-model="value2" :options="option2" :title="$t('news.paixu')" />
            <van-dropdown-item  title="筛选" ref="tab_filter">
                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">服务方式</div>
                    <div class="home_tabs">
                        <div class="home_tabs_item" :class="{ 'home_tabs_active': home_tabs_index == item.id }"
                            v-for="(item, index) in home_tabs" :key="index" @click="home_tabs_index = item.id">{{
                            item.name }}</div>
                    </div>
                </div>
                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">服务状态</div>
                    <div class="home_tabs">
                        <div class="home_tabs_item" :class="{ 'home_tabs_active': tabs_server_status == item.id }"
                            v-for="(item, index) in tabs_server_status_list" :key="index"
                            @click="tabs_server_status = item.id">{{ item.name }}</div>
                    </div>
                </div>
                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">年龄</div>
                    <div class="age_info">

                        <van-stepper v-model="age_min" integer /> ~ <van-stepper v-model="age_max" integer />
                    </div>
                </div>
                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">胸围</div>

                    <div class="home_tabs">
                        <div class="home_tabs_item" style="width: auto;"
                            :class="{ 'home_tabs_active': tabs_seChest == item.id }"
                            v-for="(item, index) in tabs_seChest_list" :key="index" @click="tabs_seChest = item.id">{{
                            item.name }}</div>
                    </div>
                </div>

                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">语种</div>

                    <div class="home_tabs">
                        <div class="home_tabs_item" style="width: auto;"
                            :class="{ 'home_tabs_active': tabs_lang == item.id }" v-for="(item, index) in tabs_lang_list"
                            :key="index" @click="tabs_lang = item.id">{{ item.name }}</div>
                    </div>
                </div>

                <div class="dropdown-cell">
                    <div class="dropdown-cell-title">身高</div>

                    <div class="home_tabs">
                        <div class="home_tabs_item" style="width: auto;"
                            :class="{ 'home_tabs_active': tabs_height == item.id }"
                            v-for="(item, index) in tabs_height_list" :key="index" @click="tabs_height = item.id">{{
                            item.name }}</div>
                    </div>
                </div>
                
                <div class="tab_footer">
                        <van-button  class="tab_footer_l"  color="#f5f5f5"  style="margin-right: 10px;color: #000;" block round @click="tab_close">
                            重置
                        </van-button>

                            <van-button  color="#f676b0"  block round  @click="tab_submit">
                                确认
                            </van-button>
                    </div>
            </van-dropdown-item>
        </van-dropdown-menu> -->

        <div class="home-scroll">



            <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')"
                :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">
                <van-list v-model="loading" :finished="finished" :offset="50" :immediate-check="false" :loading-text="$t('refresh.loading')"
                    :finished-text="$t('video.no_more')" @load="onLoad">
                    <div class="movie_list_n" v-if="movielist_1.length > 0">
                        <div class="movie-list-n-item"
                            v-for="(v, key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
                            <div style="position: relative;">
                                <img class="movie-list-n-item-img" :src="v.img_url" alt="">
                                <div class="movie-list-n-item-addr">{{$t('news.juli')}} {{ v.distance }}</div>
                            </div>
                            <div class="movie-list-n-item-bottom">

                                <div class="movie-n-time-div">
                                    <div class="movie-list-addr">
                                        <!-- <van-icon name="location-o" /> -->
                                        {{ v.xuanfei_name }}
                                    </div>
                                    <div class="movie-list-ico" @click.stop="likeTap(v,key)">
                                        <van-icon name="like" color="#f676b0" v-if="v.is_love" />
                                        <van-icon name="like-o" color="#f676b0" v-else />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </van-list>

            </van-pull-refresh>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            movielist_1: [],
            loading: false,
            finished: false,
            isLoading: true,
            token: localStorage.getItem('token'),
            page: 1,
            home_tabs: [
                {
                    name: this.$t('news.daodian'),
                    id: 1
                },
                {
                    name:this.$t('news.daojia'),
                    id: 2
                },
                {
                    name: this.$t('news.nvyou'),
                    id: 3
                },
                {
                    name: this.$t('news.lvyou'),
                    id: 4
                }
            ],
            home_tabs_index: 1,
            tabs_server_status: 1,
            tabs_server_status_list: [
                {
                    name: this.$t('news.manlu'),
                    id: 1
                },
                {
                    name: this.$t('news.kongxian'),
                    id: 2
                }
            ],

            tabs_seChest: 1,
            tabs_seChest_list: [
                {
                    name: 'B',
                    id: 1
                },
                {
                    name: 'C',
                    id: 2
                },
                {
                    name: 'D',
                    id: 3
                },
                {
                    name: 'E',
                    id: 4
                },
                {
                    name: 'F',
                    id: 5
                },
                {
                    name: this.$t('news.buxian'),
                    id: 0
                }
            ],

            tabs_lang_list: [
                {
                    name: '中国',
                    id: 1
                },
                {
                    name: '日本',
                    id: 2
                },
                {
                    name: '韩国',
                    id: 3
                },
                {
                    name: '英国',
                    id: 4
                },
                {
                    name:this.$t('news.buxian'),
                    id: 0
                }
            ],
            tabs_lang: 1,
            tabs_height_list: [
                {
                    name: '150-160',
                    id: 1
                },
                {
                    name: '161-170',
                    id: 2
                },
                {
                    name: this.$t('news.qita'),
                    id: 3
                },
                {
                    name: this.$t('news.buxian'),
                    id: 4
                }
            ],
            tabs_height: 1,

            value1: 0,
            value2: 'a',
            option1: [
                { text: '1km', value: 1 },
                { text: '3km', value: 2 },
                { text: '5km', value: 3 },
                { text: '10km', value: 4 },
                { text: '15km', value: 5 },
            ],
            option2: [
                { text: this.$t('news.buxian'), value: 'a' },
                { text:this.$t('news.julizuijin'), value: 'b' },
                { text: this.$t('news.renqizuigao'), value: 'c' },
                { text: this.$t('news.dingdanzuiduo'), value: 'd' },
                { text:this.$t('news.haopingzuiduo'), value: 'e' },
            ],
            age_min: 18,
            age_max: 50
        };
    },
    methods: {
    likeTap(item,key){
        
      if(!localStorage.getItem('token')){
        return this.$router.push({path:'/Login'})
      }
      // this.movielist_1[key]['is_love'] = !this.movielist_1[key]['is_love']
      this.$http({
        method: 'get',
        url: 'doLove',
        data: { xf_id: item.id }
      }).then(res => {
        if(res.code == 200){
          this.$set(this.movielist_1[key],'is_love',!this.movielist_1[key]['is_love'])
        }
        this.$toast(res.msg)
      });
    },
    toPlayVideo(id) {

      if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
        this.$router.push({path:'/Login'})
      } else {
        if (id) {
         this.$router.push({path:'/technicianDetail?id='+id})
        }
      }
    },
        tab_close(){
            this.home_tabs_index = 1
            this.tabs_server_status = 1
            this.tabs_seChest = 1
            this.tabs_lang = 1
            this.$refs.tab_filter.toggle();
        },
        tab_submit(){

            this.movielist_1 = [];
            this.getNhomeList()
            this.tab_close()
        },

        onRefresh() {
            setTimeout(() => {
                this.page = 1
                // this.movielist_1 = []
                this.getNhomeList();
                this.isLoading = false;
                this.$toast(this.$t('reservation.refresh'));
            }, 500);
        },
        onLoad() {
            this.loading = true;
            this.getNhomeList();
            let timer = setTimeout(() => {
                if (this.refreshing) {
                    this.movielist_1 = [];
                    this.refreshing = false;
                }
                // this.loading = false;
                if (this.movielist_1.length === this.count) {
                    this.finished = true;
                }
                this.finished && clearTimeout(timer);//清除计时器
            }, 500);
        },
        getMovieList_1(data) {
            this.movielist_1 = data.movielist_1
        },

        getNhomeList() {
            this.$http({
                method: 'get',
                url: 'xuanfeilist',
                data: { page: this.page, type: this.home_tabs_index },
            }).then(res => {
                console.log(res.data, 999)
                this.loading = false;
                if (this.page == 1) {
                    this.movielist_1 = res.data;
                } else {

                    this.movielist_1 = this.movielist_1.concat(res.data);
                }
                if (res.data.length == 0) {
                    this.finished = true;
                } else {
                    this.finished = false;
                }
                this.count = res.data.count;
                this.page++;
            })

        }
    },
    created() {

        this.getNhomeList()
    }
}

</script>

<style lang='less' scoped>
@notice-bar-size: 30px;
@movie-list-item-bottom-size: 25px;

.linear-bg {
    height: 200px;
    display: flex;
    align-items: center;

    background: linear-gradient(180deg, #F676B0 1%, #f5f5f5 119%) !important;
}

.home-container {
    position: absolute !important;
    top: 0;
    left: 0;
    right: 0;
    background-color: #f5f5f5;
}

.linear-gradient {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(126, 86, 120, 0), rgb(230, 195, 161), rgba(126, 86, 120, 0));
}

::v-deep .van-notice-bar__left-icon,
.van-notice-bar__right-icon {
    min-width: 40px;
}

.notice-swipe {
    width: calc(100% - 50px);
    height: 85px;
    font-size: @notice-bar-size;
}

::v-deep .van-icon-bullhorn-o::before {
    transform: scale(2.5);
}

.banner {
    width: 100%;
    padding: 0 30px;
    // margin-top: -23%;
}

.banner_swiper {
    height: 100%;
    width: 100%;

    .swiper-slide {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 620px;
        height: 300px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        background-color: #ffffff;
        background-position: center;
        background-size: cover;
        color: #ffffff;
    }
}

::v-deep .swiper-container-3d .swiper-slide-shadow-left {
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

::v-deep .swiper-container-3d .swiper-slide-shadow-right {
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
}

.banner_img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.hot-game {
    width: 100%;
    height: 100%;
    margin-top: 30px;
}

.hot-title-div {
    width: calc(100% - 50px);
    margin: 0 auto;
    // height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.hot-title-div>div:first-child {
    width: 430px;
}

.hot-title-div div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.hot-title-div>div:nth-child(2) span {
    font-size: 20px;
    color: #979799;
}

.hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 10px;
    font-weight: 700;
    color: #303133;
}

.hot-title-div>div:first-child span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 30px;
    font-weight: 700;
    color: #303133;
}

.hot-title-div>div:nth-child(2) span {
    font-size: 25px;
    color: #303133;
}

.hot-title-div>div:first-child span:before {
    content: "";
    display: block;
    width: 5px;
    height: 30px;
    background-color: #F676B0;
    border-radius: 1px;
    margin-right: 5px;
}

.hot-game .hot-items-div {
    margin-top: -3px;
}

.hot-game .hot-items-div span {
    margin-top: 10px;
    font-size: 24px;
    color: #303133;
}

.hot-recommend {
    width: 100%;
    flex: 1;
    background-color: #f2f2f5;
}

.movie_swiper {
    .swiper-slide {
        width: 80%;
        height: 330px;
    }
}

.movie_list_0 {
    width: calc(100% - 50px);
    margin: 0 auto;
}

.movie_cover {
    border-radius: 10px;
    width: 550px;
    height: 330px
}

.movie_list_0 .movie-list-item-bottom {
    position: relative;
    width: 550px;
    bottom: 43px;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
}

.movie_list_0 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.movie_list_0 .movie-list-item-bottom .movie-time-div .van-count-down {
    font-size: 28px;
    color: #fff;
}

.movie_list_0 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
}

.movie_list_0 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 80px;
}

.movie_list_0 span {
    font-size: 30px;
}

.movie_list_1 {
    display: flex;
    width: calc(100% - 50px);
    margin: 0 auto;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.movie_list_1 .movie-list-item .cover_img {
    border-radius: 10px;
    width: 100%;
    height: 100%;
}

.home-scroll {
    padding-bottom: 110px;
}

.movie_list_1 .movie-list-item {
    margin-bottom: 10px;
    width: 335px;
    height: 290px;
}

.movie_list_1 .movie-list-item-bottom {
    position: relative;
    width: 335px;
    bottom: 42px;

}

.movie_list_1 .movie-list-item:nth-child(odd) {
    margin-right: 10px;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div {
    background-color: rgba(0, 0, 0, .4);
}

.movie_list_1 .movie-list-item-bottom>div {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.movie_list_1 .movie-list-item-bottom .movie-time-div .van-count-down {
    color: #fff;
}

.movie_list_1 .movie-time-div {
    color: #fff;
    border-radius: 0 0 10px 10px;
    height: 35px;
}

.movie_list_1 .movie_swiper .hot {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 5px;
}

.movie_list_1 .movie-list-item .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 180px;
    padding-left: 8px;
    font-size: 25px;
}

.movie_list_1 .movie-list-item .movie-time-div span:last-child {
    overflow: hidden;
    white-space: nowrap;
    width: 0px;
    padding-right: 110px;
    font-size: 22px;
}

.movie_list_0 .movie-time-div span:first-child {
    overflow: hidden;
    white-space: nowrap;
    width: 350px;
    padding-left: 10px;
    font-size: 25px;
}

.hot-recommend-more {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
    color: #303133;
    font-size: 30px;
}

.hot-items-div {
    padding: 12px 0;
}

.hot-items-div .game_item_img {
    width: 220px;
    height: 220px;
    margin-bottom: 16px;
}

::v-deep .hot-items-div .game_item_img .van-image__img {
    border-radius: 20px;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
    color: #000000;
    font-size: 35px;
}

.mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.luckDraw {
    position: fixed;
    bottom: 160px;
    right: 40px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    z-index: 999;
}

.kefu {
    position: fixed;
    bottom: 300px;
    right: 60px;
    width: 80px;
    height: 80px;
    z-index: 999;
    // background: #fff;border-radius: 50px;
}

/deep/.van-popup__close-icon {
    font-size: 50px;
}





.movie-list-n-img {
    width: 330px;
    height: 330px;
    position: relative;
}

.bd-info .info-model .model-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    background-color: #8f65fe;
    border-radius: 0.5rem;
    margin: 0.625rem;
    padding: 0.625rem;
}

.bd-info .info-model .model-item .item-pic {
    position: relative;
    width: 7.5rem;
    overflow: hidden;
    border-radius: 10px;
    margin-right: 0.625rem;
}

.bd-info .info-model .model-item .item-info {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: .75rem;
}

.bd-info .info-model .model-item .item-pic img {
    width: 7.5rem;
    height: 11.25rem;
    border: 0;
    vertical-align: middle;
}

.bd-info .info-model .model-item .item-info .name {
    margin-bottom: 0.625rem;
    margin-top: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag {
    margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .tag span {
    font-size: .75rem;
    color: #fff;
    display: inline-block;
    margin-right: 0.3125rem;
    margin-bottom: 0.3125rem;
    background-color: #ea92cf;
    padding: 0.1875rem 0.3125rem;
    border-radius: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .info {
    margin-bottom: 0.3125rem;
}

.bd-info .info-model .model-item .item-info .remark {
    // height: 2.625rem;
    line-height: 1.6;
    overflow: hidden;
    margin-bottom: 0.75rem;

    display: -webkit-box;

    overflow: hidden;

    white-space: normal !important;

    text-overflow: ellipsis;

    word-wrap: break-word;

    -webkit-line-clamp: 3;

    -webkit-box-orient: vertical
}

.van-button {
    width: 100%;
    font-size: 28px;
    height: 2.125rem;
    border-radius: 6.1875rem !important;
}

.bd-info .info-model .model-item .item-info .remark span {
    color: #ddd2fb;
    font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .name span {
    display: inline-block;
    color: #fff;
    font-size: .875rem;
}

.bd-info .info-model .model-item .item-info .info span {
    color: #ddd2fb;
    font-size: .875rem;
}

.van-button--info,
.van-button--primary {
    border: none !important;
    background: -webkit-gradient(linear, left top, right top, from(#a082f0), to(#ea92cf)) !important;
    background: linear-gradient(90deg, #a082f0, #ea92cf) !important;
    border-radius: 5px !important;
}

.movie_list_n {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    padding-bottom: 120px;
}

.movie-list-n-item {
    padding: 10px;
    width:calc(50% - 10px);
    // background-color: #fff;
    border: 1px solid #fff;
    color: #000;
    margin: 5px;
    // margin: 15px 15px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
    box-shadow: 0 0 10px 4px rgba(202, 202, 202, 0.6);
    border: none;

}

.movie-list-n-item-top {
    padding-top: 40px;
}

.movie-list-n-item-bottom {
    // color: #fff;
    // position: absolute;
    // bottom: 15px;
    // left: 0;
    width: 100%;
    // padding-left: 10px;

}
.movie-list-addr{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
/*! autoprefixer: off */
-webkit-box-orient: vertical;
 
}
.movie-list-n-item-addr{
    position: absolute;
    padding: 8px 20px;
    border-top-left-radius: 16px;
    bottom: 0;
    right: 0;
    font-size: 24px;
    -webkit-transform: scale(.95);
    transform: scale(.95);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    background: #f676b0 !important;
    color: #fff;
}
.movie-list-n-item-img{
  width: 335px;
  height: 335px;
  display: block;
}

.movie-n-time-div {
    margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.movie-list-n-title {
    // color: #f905e5;
    color: white;
    display: -webkit-box;
    -webkit-line-clamp: 2;

    font-size: 28px;
    margin: 12px 0 12px;
    line-height: 1.4;
    height: 80px;
    overflow: hidden;
    -webkit-box-orient: vertical;
}

.movie-list-n-img {
    width: 330px;
    height: 330px;
    position: relative;
}

.movie-list-n-lab {
    color: #ffffff;
    background: #7f7f7f;
    border-color: #00b300;
    font-size: 18px;
    padding: 8px;
    margin-right: 4px;
    display: inline-block;
}

.movie-list-ico-loves {
    display: inline-block;
    width: 40px;
}


.movie-list-notice {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.movie-list-ico-notice {
    width: 30px;
    display: inline-block;
    margin-right: 10px;
}

// .movie-list-addr {
//     display: flex;
//     align-items: center;
//     margin-top: 10px;
//     font-size: 28px;
// }

.movie-list-ico-addr {
    width: 30px;
    display: inline-block;
    margin-right: 10px;
}

@keyframes rainbow_animation {

    0%,
    100% {
        background-position: 0 0;
    }

    50% {
        background-position: 100% 0;
    }
}

.movie-list-money {
    background: linear-gradient(to right, #6666ff, #0099ff, #00ff00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent !important;
    animation: rainbow_animation 10s ease-in-out infinite;
    background-size: 400% 100%;
    font-weight: bold;
    position: relative;
    margin-right: 10px;
}

.movie-list-ico-money {
    width: 30px;
    display: inline-block;
    margin-right: 10px;

}

.header {
    height: 88px;
    padding: 0 30px;
}

.addr {
    max-width: 300px;
    overflow: hidden;

    margin: 10px;
    // width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


/deep/ .van-cell {
    padding: 30px 22px;
    font-size: 30px;
    line-height: 30px;
}


.home_tabs {
    // padding: 0 30px;
    display: flex;
    //   margin: 30px 0px;
}

.home_tabs_item {
    padding: 10px 20px;
    border-radius: 30px;
    margin: 0 10px;
    background: #f5f5f5;
    text-align: center;
    font-size: 28px;
    //   font-weight: bold;
    width: calc(25% - 20px);
    display: flex;
    justify-content: center;
    align-items: center;

}

.home_tabs_active {
    background: #f676b0 !important;
    color: #fff;
}

// /deep/.van-popup{
//     background: #f5f5f5;
// }
.dropdown-cell {
    padding: 10px 30px;
    // margin-top: 20px;
    margin: 30px 0;
}

.dropdown-cell-title {
    font-size: 28px;
    margin-bottom: 20px;
}

.age_info {
    display: flex;
    // justify-content: center;
    align-items: center;
}

.tab_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
}
.tab_footer_l .van-button__text{
    color: #000;
}
</style>