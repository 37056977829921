<template>
  <div>

    <van-nav-bar class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div>
      <div>
        <!-- <van-field id="search-box" v-model="value" placeholder="請輸入地址..." /> -->

        <GmapAutocomplete @place_changed="setPlace" :placeholder="$t('news.sousuodizhi')" />
        <div class="GmapMapBox">
          <GmapMap @click="clickMap" :center="center" :zoom="zoom" style="width: 100%; height: 60vh">
            <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
              @click="clickMarker(m.position)" />
          </GmapMap>

          <div style="margin: 16px;" @click="onSubmit">
            <van-button round block type="info" native-type="submit">{{$t('my.confirm')}}</van-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { gmapApi } from 'vue2-google-maps';
export default {
  name: "GoogleMap",
  data() {
    return {
      center: { lat: 10.0, lng: 10.0 }, // 中心位置
      center_: { lat: 10.0, lng: 10.0 }, // 保存当前点位置
      currentPlace: null,
      markers: [],
      places: [],
      placeName: "",
      dialogVisible: true,
      googlemap: "",
      hasSetPin: false,
      icon: '',
      zoom: 1
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.getters.getAddress_store) {
        if (this.$store.getters.getAddress_store.user_realLat && this.$store.getters.getAddress_store.user_realLng) {
          this.center_ = {
            lat: Number(this.$store.getters.getAddress_store.user_realLat),
            lng: Number(this.$store.getters.getAddress_store.user_realLng),
          }
          this.center = {
            lat: Number(this.$store.getters.getAddress_store.user_realLat) ,
            lng: Number(this.$store.getters.getAddress_store.user_realLng) ,
          }
          this.zoom = 10
          console.log(this.$store.getters.getAddress_store,8888)
          this.markers.push({ position: this.center });
        }
      } else {

        this.geolocate();
      }
    })
  },
  computed: {
    google: gmapApi, // 获取官方的OBject 使用官方API的时候可以用
  },
  methods: {

    onSubmit() {
      this.$store.commit('setAddress_store', {
        user_realLat: this.center_.lat,
        user_realLng: this.center_.lng,
        address: this.placeName
      });
      this.back()
    },
    back() {
      window.history.back();
    },

    handleClose() { },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarkerFun();
    },
    addMarker() {
      this.addMarkerFun();
    },
    addMarkerFun() {
      console.log(this.currentPlace)
      if (this.currentPlace && this.currentPlace.adr_address) {
        this.hasSetPin = true
        this.zoom = 10
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers = [];
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.center_ = marker;
        this.placeName = this.currentPlace.name;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      let vm = this

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position && position.coords && position.coords.latitude) {
            // alert("获取地理位置："+position.coords.latitude+","+position.coords.longitude)
            vm.hasSetPin = true
            vm.zoom = 10
            vm.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            vm.center_ = vm.center
            setTimeout(()=>{

              this.reverseGeocode(vm.center)
            },1500)
            vm.markers.push({ position: vm.center });
          }
        }, (error) => {  // html5 默认调用的谷歌的接口，会有安全限制
          switch (error.code) {
            case error.PERMISSION_DENIED: // 许可拒绝,用户选了不允许
              // alert("您拒绝对获取地理位置的请求");
              // alert(error.message);
              break;
            case error.POSITION_UNAVAILABLE: // 连不上GPS卫星，或者网络断了
              // alert("位置信息是不可用的");
              // alert(error.message);
              break;
            case error.TIMEOUT:  // /超时了
              // alert("请求您的地理位置超时");
              // alert(error.message);
              break;
            case error.UNKNOWN_ERROR:
              // alert("未知错误");
              // alert(error.message);
              break;
          }
        });
      } else {
        // alert("navigator.geolocation未获取获取到地理位置");
        // vm.markers.push({ position: vm.center });
      }
    },
    reverseGeocode(location) {
      let that = this
      // 实例化Geocoder服务用于解析地址
      var geocoder = new this.google.maps.Geocoder();
      // 地理反解析
      geocoder.geocode({ location: new this.google.maps.LatLng(location.lat, location.lng) }, function geoResults(results, status) {
        if (status === 'OK') {
          console.log(results)
          that.placeName = results[0].formatted_address
        }
      });
    },
    clickMap(e) {
      this.hasSetPin = true
      // let longlat = e.latLng.lat() + "," + e.latLng.lng();
      this.center_ = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.markers = [];
      this.markers.push({ position: this.center_ });
      this.reverseGeocode(this.center_)
    },
    clickMarker(val) {
      this.center = val;
    },
    submitMap() {
      if (!this.hasSetPin) {
        this.msgError(this.$t("googlemap.searchAndAdd"));
        return
      }
      let obj = Object.assign({}, this.center_);
      obj.name = `${this.center_.lat.toFixed(5)},${this.center_.lng.toFixed(5)}`;
    },
    cancelMap() {
    },
  },
};
</script>
<style scoped lang="less">
.map {
  /* height: 85vh; */
  padding-top: 10vh;
}

.van-button {
  width: 100%;
  font-size: 28px;
  height: 2.125rem;
  border-radius: 6.1875rem !important;
  background: #f676b0;
  border-color: #f676b0;
}

.add-btn {
  width: 60px;
  margin-left: 2%;
  height: 40px;
  background: #409eff;
  color: #fff;
  border: 0;
  border-radius: 5px;
}

.search-box input {
  height: 40px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 7px;
  outline: none;
}

/* 底部两个按钮 */
.btn-box {
  margin-top: 2vh;
}

.btn-box .el-button {
  padding: 8px 30px;
  border-radius: 30px;
  width: 140px;
  height: 40px;
}

.confirm {
  color: #fff;
  background-color: #0778bc;
}

.cancel {
  color: #0778bc;
  border: 1px solid #0778bc;
}

.cursor {
  cursor: pointer;
}

@media only screen and (max-width: 820px) {
  .img-box .el-image {
    height: 4vh;
  }

  .search-box input {
    height: 5vh;
  }

  .add-btn {
    height: 5vh;
  }
}

.map .GmapMapBox {
  /* background-image: url(../assets/img/loading.gif); */
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}


/deep/ .van-cell {
  padding: 20px 30px;
  font-size: 30px;
  line-height: 30px;
  width: 80%;
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  border-radius: 50px;
}

::v-deep .gmnoprint,
::v-deep .gm-fullscreen-control {
  display: none !important;
}

.pac-target-input {
  width: 100%;
  height: 80px;
  line-height: 80px;
  border: none;
  padding: 0 30px;
}
</style>