import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        //这里放全局参数
        userInfo: {},
        baseInfo: {},
        langDefault: 'vn_vn',
        base_addr_store:localStorage.getItem('base_addr_store')?JSON.parse(localStorage.getItem('base_addr_store')):null,
        address_store: localStorage.getItem('address_store')?JSON.parse(localStorage.getItem('address_store')):null,
        order_from_store:localStorage.getItem('order_from_store')?JSON.parse(localStorage.getItem('order_from_store')):null

    },
    mutations: {
        setUserInfoValue(state, Value) {
            state.userInfo = Value
        },
        setBaseInfoValue(state, Value) {
            state.baseInfo = Value
        },
        setbase_addr_store(state, Value) {
            localStorage.setItem('base_addr_store',Value)
            if (Value) {
                state.base_addr_store = {
                    ...state.base_addr_store,
                    ...Value
                }
                localStorage.setItem('base_addr_store',JSON.stringify(Value))
            } else {
                state.base_addr_store = null
                localStorage.removeItem('base_addr_store')
            }
        },
        setAddress_store(state, Value) {
            if (Value) {
                state.address_store = {
                    ...state.address_store,
                    ...Value
                }
                localStorage.setItem('address_store',JSON.stringify(Value))
            } else {
                state.address_store = null
                localStorage.removeItem('address_store')
            }
        },
        set_order_from(state, Value){
            localStorage.setItem('order_from_store',Value)
            if (Value) {
                state.order_from_store = {
                    ...state.order_from_store,
                    ...Value
                }
                localStorage.setItem('order_from_store',JSON.stringify(Value))
            } else {
                state.order_from_store = null
                localStorage.removeItem('order_from_store')
            }
        }
    },
    getters: {
        //get方法
        getUserInfo: state => state.userInfo,
        getBaseInfo: state => state.baseInfo,
        getAddress_store: state => state.address_store,
        getbase_addr_store: state => state.base_addr_store,
        getorder_from_store: state => state.order_from_store,
    },
    actions: {
        //这个部分我暂时用不上
    },
    modules: {
        //这里是我自己理解的是为了给全局变量分组，所以需要写提前声明其他store文件，然后引入这里
    }
})