<template>
    <div class="nHome-detail">
        <van-nav-bar :title="$t('news.shoucang')" class="nav-bar">
            <template #left>
                <van-icon name="arrow-left" color="#fff" @click="back()" />
            </template>
        </van-nav-bar>
        <div style="height: 10px;"></div>
        <div v-if="list.length > 0">

            <div class="addr_list" v-for="(item,index) in list" :key="index">
                <div style="width: 70%;">
                    <div class="addr_list_username" v-if="item.info">
                        <img class="addr_list_img" :src="item.info.img_url" alt="">
                        {{item.info.xuanfei_name}} </div>
                </div>
                <div style="flex: 1;"></div>
                <!-- <div class="addr_list_close" @click="del(item)">取消</div> -->
                <van-icon @click="likeTap(item,index)" name="like" color="#f676b0" />
            </div>
        </div>
    </div>
</template>

<script>

import dayjs from 'dayjs'
import { Dialog } from 'vant';
export default {
    data() {
        return {
            dayjs,
            list:[],
            isDisabled:false
        };
    },
    created() {
        this.$http({
            method: 'get',
            url: 'lovelist'
        }).then(res => {
            console.log(res.data)
            this.list = res.data
        })

    },

    mounted() {
    },
    methods: {
    likeTap(item,key){
        Dialog.confirm({
  message: this.$t('news.jiechu'),
  cancelButtonText:this.$t('my.confirm'),
  confirmButtonText:this.$t('my.cancel')
})
  .then(() => {
    // on confirm
    if(this.isDisabled) return
        this.isDisabled = true
      this.$http({
        method: 'get',
        url: 'doLove',
        data: { xf_id: item.xf_id }
      }).then(res => {
        if(res.code == 200){
        //   this.$set(this.list[key],'is_love',!this.movielist_1[key]['is_love'])
          this.list.splice(key, 1)
          this.isDisabled = false
        }
        this.$toast(res.msg)
      });
  })
  .catch(() => {
    // on cancel
  });
           
    },
        
        back() {
            if (this.url) {

                this.$router.replace({ path: '/Mine' })
            } else {
                window.history.back();
            }
        },

    }
};
</script>

<style scoped lang="less">
.addr_list {
    display: flex;
    align-items: center;
    background: #fff;
    margin: 10px 30px;
    padding: 30px;
    border-radius: 20px;
}

.addr_list_username {
    font-size: 28px;
    display: flex;
    align-items: center;
}

.addr_list_useraddr {
    font-size: 24px;
}
.nHome-detail{
    background: #f5f5f5;
    height: 100vh;
    overflow: auto;
}

.addr_list_img{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
}
.addr_list_close{
    padding: 10px 30px;
    border-radius: 20px;
    background: red;
    color: #fff;
    font-size: 24px;
}
</style>
<style>

/* ::v-deep .van-dialog {

width: 100%;
font-size:32px !important;
height: 2.125rem !important;
} */
.van-dialog .van-button{
font-size: 14px;
}
</style>