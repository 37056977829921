import axios from 'axios'
import api from './api'
import Vue from 'vue';
let Base64 = require('js-base64').Base64;
import store from '@/store'
import { Toast } from 'vant';
import qs from "qs";

Vue.use(Toast);
//创建axios实例对象
export const baseURL = window.location.protocol + '//api.' + document.domain.match(/[^.]*\.[^.]*$/)[0]
const instance = axios.create({
    // baseURL: 'https://api.onlinegirl.vip', //服务器地址
    baseURL: baseURL + '/api', //服务器地址
    timeout: 90000, //默认超时时长
})
// 'https://api.1564584.vip/api'
//window.location.protocol + '//api.' + document.domain.match(/[^.]*\.[^.]*$/)[0]+'/api' window.location.protocol是http或者https

//请求拦截器
instance.interceptors.request.use(config => {
    if (config.method === "post") {
        config.headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // 设置跨域头部
        }
    }
    if (localStorage.getItem('token')) {
        config.headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // 设置跨域头部
            'lang': localStorage.getItem("viodeLang")?.toLowerCase() || store.state.langDefault,
            'tokensso': localStorage.getItem('tokensso'),
            'token': Base64.encode(localStorage.getItem('token'))
        }
    } else {
        config.headers = {
            'content-type': 'application/x-www-form-urlencoded;charset=UTF-8', // 设置跨域头部
            'lang': localStorage.getItem("viodeLang")?.toLowerCase() || store.state.langDefault
        }
    }

    return config
}, err => {
    console.error('请求失败', err)
})

//响应拦截器
instance.interceptors.response.use(res => {
    //此处对响应数据做处理
    if (res.data.msg === "鉴权错误" || res.data.msg == 'xác thực lỗi') {
        localStorage.clear();
        // this.$router.push({ path: '/Login' })
        if (navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
            location.href = '/#/Login'
        } else {
            window.location.href = '/#/Login'

        }
    }
    return res //该返回对象会传到请求方法的响应对象中
}, err => {
    // 响应错误处理
    return Promise.reject(err);
})

//封装axios请求方法，参数为配置对象
//option = {method,url,params} method为请求方法，url为请求接口，params为请求参数
async function http(option = {}) {
    option.data = {
        ...option.data,
        'realLat': localStorage.getItem('lat'),
        'realLng': localStorage.getItem('lng')
    }
    let result = null
    if (option.method === 'get' || option.method === 'delete') {
        //处理get、delete请求
        await instance[option.method](
            api[option.url],
            { params: option.data }
        ).then(res => {
            result = res.data
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err
        })
    } else if (option.method === 'post' || option.method === 'put') {
        //处理post、put请求
        await instance[option.method](
            api[option.url],
            qs.stringify(option.data)
        ).then(res => {
            result = res.data;
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err
        })
    } else if (option.method === 'file') {
        await instance['post'](
            api[option.url],
            option.data.file,
            option.headers
        ).then(res => {
            result = res.data;
            result.data = JSON.parse(Base64.decode(result.data));
        }).catch(err => {
            result = err
        })
    }
    return result
}

export default http

